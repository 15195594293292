import React from "react";
import Banner from "containers/visuella/Banner";
import AboutUs from "containers/visuella/AboutUs";

const AboutUsPage = () => {
  return (
    <>
      {/* <AboutUs /> */}
    </>
  );
};
export default AboutUsPage;
